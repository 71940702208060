.intro-container {
  margin-top: 30vh;
  margin-left: 20vw;
  margin-right: auto;
  width: 60%;
  height: 50vh;
  background-color: white;

  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;

  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.profile-image {
  width: 325px;
  margin-left: 5%;
  border-radius: 10px;
}

.description {
  color:#888888;
}



@media screen and (min-width: 948px) {
  .intro-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .intro-p {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (max-width: 1261px) {
  .intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .profile-image {
    width: 50%;
    margin-top: 20px;
  }

  .intro-p {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 360px) {
  .intro-container {
    font-size: 8px;
    height: 30%;
    margin-top: 30%;
    margin-bottom: 50px;
    width: 100%;
    margin-left: auto;
  }
}
