.web-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20vh;
  width: 90vw;
  height: auto;
  background-color: white;

  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;

  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 100px;
}

.web-pic {
  width: 70%;
}

.web-pic:hover {
  cursor: pointer;
}

.link:hover {
  cursor: pointer;
}

.project-container {
  display: flex;
  flex-direction: column;
  
  width: 500px;
  margin-top: 20px;
  margin-left: 2%;
  margin-right: 2%;
}

.description {
  color:#888888;
  margin-top: 10px;
}

@media screen and (max-width: 1274px) {
  .web-container {
    font-size: 16px;
    line-height: normal;
  }
}

@media screen and (max-width: 1080px) {
  .web-container {
    flex-direction: column;
    align-items: center;
  }

  p {
    width: 80%;
  }

  

  .project-container {
    margin-left: 1px;
  }
}

