ul {
  list-style-type: none;
}

li {
  float: left;
  padding-right: 10px;
}

li:hover {
  text-decoration: underline;
  cursor: pointer;
}