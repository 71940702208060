.game-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20vh;
  width: 70vw;
  height: auto;
  background-color: white;

  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;

  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 100px;
}

.game-pic {
  width: 100%;
}

.game-pic:hover {
  cursor: pointer;
}

.game-project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  margin-left: 10%;
}

@media screen and (max-width: 1200px) {
  .game-container {
    flex-direction: column;
    align-items: center;
  }

  .game-pic {
    width: 160%;
  }

}

@media screen and (max-width: 360px) {
  .game-container {
    width: 100%;
    margin: 0;
    margin-top: 35%;
    margin-bottom: 10%;
  }

  .game-project-container {
    width: 90%;
    margin: 0;
  }
  .game-pic {
    width: 100%;
  }
}