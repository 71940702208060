.footer {
  position: fixed;
  bottom: 0px;
  background-image: linear-gradient(
      to right bottom,
      rgba(76, 216, 255),
      rgba(30, 108, 217)
  );

  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}

.footer-icons {
  font-size: 30px;
  margin-right: 20px;
}