.about-container {
  margin-top: 30vh;
  width: 40vw;
  height: auto;
  background-color: white;

  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;

  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.icon {
  font-size: 50px;
  margin-right: 30px;
}

.icon:hover {
  cursor: pointer;
  color: rgba(30, 108, 217, 0.8);
}

a.icon-link {
  color: black;
}

a.icon-link:visited {
  color: black;
}

@media screen and (max-width: 360px) {
  .about-container {
    margin-left: auto;
    width: 100vw;
  }
}